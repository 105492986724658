<script lang="ts">
  import { onMount, setContext } from 'svelte'
  import { writable } from 'svelte/store'

  import { ContentKind } from '../../store/content-kind'
  import { AnyContentKind } from '../../store/kind'
  import { state } from '../../store/state'

  import { FilterManager } from '../content/filter/filter-manager'
  import { Presenter } from '../content/presenter'
  import Search from '../search/search.svelte'
  import NewContentButtons from '../content/new-content/new-content-buttons.svelte'
  import DateRangeFilter from '../content/filter/date-range-filter.svelte'

  import ResultPopover from './result-popover.svelte'
  import { SEARCH_ITEMS_PER_PAGE } from './result-popover'

  const routesHistory = writable<any>({})
  const originalContentKind = state[AnyContentKind.nameOnState] as ContentKind<any>
  const contentKindClone = originalContentKind.clone()

  const { pageSize } = contentKindClone
  const filterManager = new FilterManager(contentKindClone, state)

  const presenter = new Presenter(state, contentKindClone, filterManager, routesHistory)

  const SEARCH_WIDTH_TOLERANCE = 5

  let ftsFieldFocused = false

  let container
  let searchWidth = 0

  setContext(Presenter.key, {
    presenter,
    contentKind: contentKindClone,
    selectedContentId: presenter.selectedContentId,
  })

  const getSearchWidth = () => {
    if (!state.currentHash.get().startsWith('#/home')) {
      return
    }

    if (!container) {
      return
    }

    const child = container.querySelector('.home-search')

    if (!child) {
      return
    }

    const newWidth = child.offsetWidth

    if (!newWidth) {
      return
    }

    searchWidth = newWidth - SEARCH_WIDTH_TOLERANCE
  }

  const unsubscribe = state.subscribeTo('currentHash', 'subscribe', (value) => {
    if (!value.startsWith('#/home')) {
      return
    }

    $pageSize = SEARCH_ITEMS_PER_PAGE
    getSearchWidth()
  })

  onMount(() => {
    getSearchWidth()
    window.addEventListener('resize', getSearchWidth)

    return () => {
      window.removeEventListener('resize', getSearchWidth)
      unsubscribe()
    }
  })
</script>

<div bind:this={container} class="main-home flex flex-col w-full">
  <div class="m-auto">
    <div class="home-search flex gap-6 w-full">
      <div class="w-full">
        <Search {filterManager} bind:ftsFieldFocused showNewContent={false} />
      </div>
      <div class="h-full">
        <DateRangeFilter filter={filterManager.date} />
      </div>
    </div>
    <ResultPopover open={ftsFieldFocused} {presenter} width={searchWidth} />
    <div class="auto-grid pl-1 pt-8 max-2xl:pt-4">
      <NewContentButtons smallResponsive={true} />
    </div>
  </div>
</div>

<style lang="postcss">
  .main-home :global(.bx--dropdown),
  .main-home :global(.single-list),
  .main-home :global(.date-range-filter) {
    min-height: 3rem;
  }

  :global(#mytype-body) .main-home :global(.search-wrapper) {
    gap: 0;
  }

  .auto-grid {
    height: 80%;
    max-width: 100rem;
  }

  @media (max-width: 1536px) {
    .auto-grid {
      max-width: 72rem;
    }
  }

  .auto-grid {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
  }

  .main-home :global(.saved-search-container),
  .main-home :global(.archive-search-toggle) {
    display: none;
  }
</style>
