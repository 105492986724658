<script lang="ts">
  import RightSidebar from './right-sidebar.svelte'
  import Main from './main.svelte'
</script>

<div class="flex w-full h-full">
  <div class="flex justify-center items-center w-full">
    <Main />
  </div>
  <RightSidebar />
</div>
