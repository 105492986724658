<script lang="ts">
  import { onMount } from 'svelte'
  import { Popover } from 'carbon-components-svelte'

  import FakeLink from '../../style/fake-link.svelte'
  import { state } from '../../store/state'
  import { Presenter } from '../content/presenter'
  import Grid from '../content/grid.svelte'
  import ContentCellRenderer from '../content/search-presenter/content-cell-renderer.svelte'

  import { openSearch, SEARCH_ITEMS_PER_PAGE } from './result-popover'
  import { isHome } from '../../routing/router-utils'

  export let open = false
  export let presenter: Presenter
  export let width: number

  let internalOpen = open

  const { contentKind } = presenter
  const { filter, currentPage, total, pageSize } = contentKind

  let popoverRef

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.stopPropagation()
    }
  }

  const handleWheel = (event: WheelEvent) => event.stopPropagation()

  const handleClickOutside = (event: MouseEvent) => {
    if (!popoverRef || !popoverRef.contains(event.target)) {
      internalOpen = open
      return
    }

    internalOpen = true
  }

  const doTheMath = (open: boolean) => {
    if (open) {
      $pageSize = SEARCH_ITEMS_PER_PAGE
      internalOpen = open
      return
    }

    $currentPage = 1
  }

  $: doTheMath(open)

  const addRemoveListeners = () => {
    removeEventListeners()

    if (isHome(state)) {
      document.addEventListener('keydown', handleKeydown, true)
      document.addEventListener('wheel', handleWheel, true)
      document.addEventListener('click', handleClickOutside, true)
    }
  }

  const removeEventListeners = (doc = document) => {
    doc.removeEventListener('keydown', handleKeydown, true)
    doc.removeEventListener('wheel', handleWheel, true)
    doc.removeEventListener('click', handleClickOutside, true)
  }

  onMount(() => {
    const unsubscribeCurrentHash = state.currentHash.listen(() => {
      addRemoveListeners()
    })

    addRemoveListeners()

    const doc = document
    return () => {
      removeEventListeners(doc)
      unsubscribeCurrentHash()
    }
  })
</script>

<div class="result-popover relative -mt-7 w-fit" style="--popover-width: {width}px">
  <Popover open={internalOpen && $filter.fts !== ''} align="bottom-left">
    {#if !$total}
      <div class="p-4">No contents found...</div>
    {:else}
      <div bind:this={popoverRef} class="z-50 max-h-[20rem] min-h-[20rem] overflow-y-auto">
        <Grid containerHeight={350} CellRenderer={ContentCellRenderer} on:pageResize={() => {}} />
      </div>
      <div class="w-full h-fit flex gap-1 justify-between p-2 border-t border-solid border-[var(--selected)]">
        <div class="flex h-full items-center justify-center">
          {#if $total > 50}
            <span>{$total} contents in total (50 displayed here)</span>
          {:else}
            <span>{$total} contents in total</span>
          {/if}
        </div>
        <div class="w-fit h-fit flex gap-1">
          {#if $total > 50}
            <span>To see more results </span>
          {:else}
            <span>To see all available filters </span>
          {/if}
          <FakeLink>
            <div role="button" tabindex="-1" on:keypress on:click={() => openSearch(contentKind, state)}>
              Open search
            </div>
          </FakeLink>
        </div>
      </div>
    {/if}
  </Popover>
</div>

<style lang="postcss">
  .result-popover :global(.bx--popover-contents) {
    margin-left: 5px;
    max-width: unset;
    width: var(--popover-width);
  }

  .result-popover :global(thead) {
    position: sticky;
    top: 0;
    z-index: 50;
  }
</style>
