import { router } from '../../routing/router'
import { ContentKind } from '../../store/content-kind'
import { State } from '../../store/state'

export const SEARCH_ITEMS_PER_PAGE = 50

export const openSearch = (contentKind: ContentKind<any>, state: State) => {
  const currentSearch = router(state).route().params || {}

  router(state).upsertQueryParams(currentSearch).navigate(contentKind.kind.nameOnState)
}
