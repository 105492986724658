<script lang="ts">
  import { onDestroy } from 'svelte'
  import { state } from '../../store/state'
  import SavedSearchDetails from '../search/saved-search/saved-search-details.svelte'
  import { type LocalSavedSearch } from '../../store/user-data/saved-searches'

  let homeSearch: LocalSavedSearch | undefined = undefined

  const unsubscribe = state.localSearches.subscribe((searches) => {
    homeSearch = searches.find((search) => search.isInHome)
  })

  onDestroy(() => unsubscribe())
</script>

<div class="m-4 w-fit">
  <SavedSearchDetails data={homeSearch} showIcons={false} />
</div>
